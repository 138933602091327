<template>
  <div class="reportPage">
    <h2 class="mt-5">{{ $t(config.crudHeader) }}</h2>
    <v-container fluid>
      <v-btn color="danger" hide-details small id="print" @click="print">{{
        $t("Print")
      }}</v-btn>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("NameAr") }}</th>
            <th>{{ $t("NameEn") }}</th>
             <th>{{ $t("Country") }}</th>
            <th>{{ $t("City") }}</th>
            <th>{{ $t("Status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="index">
            <td>{{ row.id }}</td>
            <td>{{ row.name.ar }}</td>
            <td>{{ row.name.en }}</td>
            <td>{{ row.country_name }}</td>
            <td>{{ row.city_name }}</td>
          
            <td>
              <span v-if="row.status == 1" style="color: green">{{
                $t("Active")
              }}</span>
              <span v-else-if="row.status == 0" style="color: red">{{
                $t("InActive")
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      config: {
        crudHeader: "Branches",
        crudApi: "branch",
      },
    };
  },
  methods: {
    initData() {
      this.$store
        .dispatch(`${this.config.crudApi}/listAll`)
        .then((res) => {
          this.items = res.data;
          localStorage.removeItem("filterReport");
        });
    },
    print() {
      window.print();
    },
  },
  created() {
    let app_title = document.getElementById("app_title");
    app_title.innerText = this.$t(this.config.crudHeader);
    this.initData();
  },
};
</script>